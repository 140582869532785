@keyframes slide {
    0% {
        transform: translateX(-25%);
    } 100% {
        transform: translateX(25%);
    }
}

.bg {
    animation: slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #b8dbd9 50%, #586F7C 50%);
    bottom: 0;
    left: -50%;
    opacity: .5;
    position: fixed;
    right: -50%;
    top: 0;
    z-index: 1;
}

.bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 4s;
}

.bg3 {
    animation-duration: 5s;
}

@media (prefers-reduced-motion) {
  .bg, .bg2, .bg3 {
    animation-name: dissolve;
  }
}