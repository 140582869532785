* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}

.theme-light {
  --dark-text: #5E4B56;
  --accent: #dbe7e4;
  --button-border: #5E4B56;
  --dark-background: #DBE7E4;
  --light-text: #5E4B56;
  --light-background: #EDDCD2;
}

/* --light-text is fine on on the first 3, --dark-text is fine on all of them, --dark-text and dark-background are fine as text on --accent */
.theme-dark {
  --dark-text: #292929;
  --dark-background: #2F4550;
  --light-background: #586F7C;
  --accent: #b8dbd9;
  --button-border: #b8dbd9;
  --light-text: #F9F8F8;
}

html, #root {
  font-family: 'Roboto Mono', monospace;
  color: var(--dark-text);
  height: 100vh;
}

body {
  height: 100vh;
  background-color: var(--light-background);
}

button {
  font-family: 'Roboto Mono', monospace;
}